<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>New Territory</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          to="/setup/territories"
        >
          <v-icon
            left
            dark
          >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor
          :initial="dataItem"
          @data="save"
        ></editor>
        <!-- end -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
  }),
  methods: {
    save(data) {
      const url = "/territories";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$router.push("/setup/territories");
        })
        .finally(() => {
          // location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>